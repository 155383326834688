import React from 'react'

import Layout from '../components/layout'
import '../styles/main.scss'

// TODO: Create blog listing

const IndexPage = () => (
  <Layout>
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
  </Layout>
)

export default IndexPage
